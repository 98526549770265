import React, { createContext, useState } from 'react';

const DataContext = createContext();

const DataProvider = ({ children }) => {
    const [data, setData] = useState({ country: '', response1: '', response2: '', response3: '', response4: '', response5: '', response6: '', response7: '' });

    const saveData = (newData) => {
        setData(prevData => ({
            ...prevData,
            ...newData
        }));
    };
    return (
        <DataContext.Provider value={{ data, saveData }}>
            {children}
        </DataContext.Provider>
    );
};

export { DataContext, DataProvider };