import React from 'react';
import './Header.css';
import ImageLogo from './images/logo-ferring.png';


const Header = () => {
    return (
        <div className="header">
            <img src={ImageLogo} alt="Ferring" className="logo" />
        </div>
    )
}

export default Header;