import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './components/homepage/HomePage';
import Quiz from './components/quiz/Quiz';
import EndPage from './components/endpage/EndPage';
import Header from './components/header/Header';
import { DataProvider, DataContext } from './components/context/DataContext';
import './App.css';


function App() {
  // const { data } = useContext(DataContext);

  // useEffect(() => {
  //   console.log('App.js - Global Data:', data);
  // }, [data]);
  return (
    <DataProvider>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/end" element={<EndPage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </DataProvider>
  );
}

export default App;
