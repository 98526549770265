import React, { useState, useEffect, useContext } from 'react';
import './Quiz.css';
import { DataContext } from '../context/DataContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import StarRating from '../starrating/StarRating';

const Quiz = () => {
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentAnswer, setCurrentAnswer] = useState('');
    const [textAnswers, setTextAnswers] = useState({}); // Utilisation d'un objet pour stocker les réponses textuelles
    const { data, saveData } = useContext(DataContext);
    const [step, setStep] = useState(1);
    const [answerId, setAnswerId] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://ferring202409.cmeneux.com/api/questions')
            .then(response => {
                setQuestions(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the questions!', error);
            });
    }, []);

    useEffect(() => {
        const currentQuestion = questions[currentQuestionIndex];
        if (currentQuestion && currentQuestion.hasRange) {
            setCurrentAnswer('1'); // Initialiser le range à 1 par défaut
        } else {
            setCurrentAnswer(''); // Réinitialiser pour d'autres types de questions
        }
    }, [currentQuestionIndex, questions]);

    const handleAnswerChange = (event) => {
        setCurrentAnswer(event.target.value);
    };

    const handleTextAnswerChange = (e, answerId) => {
        const { value } = e.target;
        // console.log(value);
        setTextAnswers(prev => ({
            ...prev,
            [answerId]: value // Stocker la réponse textuelle pour chaque question/réponse
        }));
        // console.log('textAnswers:', textAnswers);
        setAnswerId(answerId);
    };

    const handleRangeChange = (e) => {
        setCurrentAnswer(e.target.value);
    };

    const handleNextQuestion = () => {
        let newData = {
            [`response${step}`]: currentAnswer,
            [`responseText${step}`]: textAnswers[answerId] || ''  // Utilisation de camelCase
        };
        console.log('newData:', newData);
        if (step === 11) {
            sendDataToApi({ ...data, ...newData });
        } else {
            saveData(newData);
            setStep(prevStep => prevStep + 1);
            setTextAnswers({}); // Réinitialiser les réponses textuelles pour la prochaine question
        }

        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        }
    };

    const handlePrevQuestion = () => {
        if (currentQuestionIndex > 0) {
            setStep(prevStep => prevStep - 1);
            setCurrentQuestionIndex(prevIndex => prevIndex - 1);
            const previousAnswer = data[`response${step - 1}`];
            setCurrentAnswer(previousAnswer || '');
        }
    };

    const sendDataToApi = async (dataToSend) => {
        try {
            console.log('Data to send:', dataToSend);
            const response = await axios.post('https://ferring202409.cmeneux.com/api/responses', dataToSend);
            console.log('Data sent successfully:', response.data);
            navigate('/end');
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    if (questions.length === 0) {
        return (
            <div className="loading-container">
                <div className="spinner-border text-primary spinner-custom" role="status">
                </div>
            </div>
        );
    }

    const currentQuestion = questions[currentQuestionIndex];

    const handleStarRatingChange = (rating) => {
        setCurrentAnswer(rating.toString());
    };

    return (
        <div className="page-content">
            <h1 className="title">{currentQuestion.title}</h1>
            <p>{currentQuestion.text}</p>

            {currentQuestion.isOpen ? (
                <div className="form-group mt-5">
                    <textarea
                        type="text"
                        name="answer"
                        className="form-control"
                        value={currentAnswer}
                        onChange={handleAnswerChange}
                    ></textarea>
                </div>
            ) : currentQuestion.isStar ? (
                <div className="d-flex justify-content-center mt-5">
                    <StarRating
                        rating={parseInt(currentAnswer) || 0}
                        onRatingChange={handleStarRatingChange}
                    />
                </div>
            ) : currentQuestion.hasRange ? (
                <div className="form-group mt-5">
                    <label htmlFor="rangeInput">Select a value (1-10): {currentAnswer}</label>
                    <div class="zone-range">
                    <input
                        type="range"
                        id="rangeInput"
                        name="rangeInput"
                        min="1"
                        max="10"
                        value={currentAnswer}
                        onChange={handleRangeChange}
                        className="form-range"
                    />
                    </div>
                </div>
            ) : (
                <div className="d-flex justify-content-center mt-5">
                    <table className="text-start mb-5">
                        <tbody>
                            {currentQuestion.answsers && currentQuestion.answsers.length > 0 ? (
                                currentQuestion.answsers.map((answer) => (
                                    <tr key={answer.id}>
                                        <td>
                                            {answer.isText ? (
                                                <div className="form-group mt-3">
                                                    <label htmlFor={answer.id} className="form-check-label2">
                                                        {answer.text}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control mt-2 text-answer"
                                                        placeholder="Enter your answer"
                                                        value={textAnswers[answer.id] || ''} // Utilisation de l'objet textAnswers
                                                        onChange={(e) => handleTextAnswerChange(e, answer.id)}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                <div className="form-check">
                                                    <input
                                                        type={currentQuestion.multiple ? 'checkbox' : 'radio'}
                                                        className="me-3"
                                                        id={answer.id}
                                                        name="answer"
                                                        value={answer.text}
                                                        checked={currentAnswer === answer.text}
                                                        onChange={handleAnswerChange}
                                                    />
                                                    <label htmlFor={answer.id} className="form-check-label">
                                                        {answer.text}
                                                    </label>
                                                </div>
                                                    {answer.hasText && (
                                                        <input
                                                            type="text"
                                                            className="form-control mt-2 text-answer"
                                                            placeholder="Enter additional text"
                                                            value={textAnswers[answer.id] || ''} // Gestion des réponses textuelles supplémentaires
                                                            onChange={(e) => handleTextAnswerChange(e, answer.id)}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td>No answers available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}

            <div className="button-group mt-5">
                {currentQuestionIndex > 0 && (
                    <button onClick={handlePrevQuestion} className="btn-commencer me-5">
                        <i className="fa-solid fa-arrow-left"></i>
                    </button>
                )}
                <button onClick={handleNextQuestion} className="btn-commencer">Next</button>
            </div>
        </div>
    );
};

export default Quiz;
