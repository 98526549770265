import React, { useContext, useState, useEffect } from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataContext } from '../context/DataContext';

const HomePage = () => {
    let navigate = useNavigate();
    const { data, saveData } = useContext(DataContext);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(data.country);
    const [homeContent, setHomeContent] = useState({});

    const handleClick = () => {
        navigate('/quiz');
    };

    useEffect(() => {
        axios.get('https://ferring202409.cmeneux.com/api/countries')
            .then(response => {
                setCountries(response.data.filter(country => country.isInList === true));
            })
            .catch(error => {
                console.error('There was an error fetching the countries!', error);
            });
        axios.get('https://ferring202409.cmeneux.com/api/homes')
            .then(response => {
                setHomeContent(response.data[0]); // Assurez-vous d'adapter cette ligne selon la structure de votre API
            })
            .catch(error => {
                console.error('There was an error fetching the home content!', error);
            });
    }, []);

    const handleChange = (event) => {
        const newCountry = event.target.value;
        setCountry(newCountry);
        saveData({ country: newCountry });
    };

    return (
        <main className="home-container">
            <h1>{ homeContent.title }</h1>
            
            {/* Utilisation de dangerouslySetInnerHTML pour afficher le contenu HTML */}
            <div className='mt-5 txt-accueil' dangerouslySetInnerHTML={{ __html: homeContent.text }}></div>
            
            <div className="mt-5 mx-md-4">
                <select id="country" onChange={handleChange} className="form-select text-center" aria-label="Country select" required>
                    <option value="">Select your country</option>
                    {countries.map((country) => (
                        <option key={country.id} value={country.enShortName}>
                            {country.enShortName}
                        </option>
                    ))}
                </select>
            </div>
            <footer className="mt-5 align-self-end w-100">
                <button onClick={handleClick} className="btn btn-commencer">Start</button>
            </footer>
        </main>
    );
};

export default HomePage;
