import React from 'react';
import starFilled from './images/star-filled.svg';
import starEmpty from './images/star-empty.svg';

const StarRating = ({ rating, onRatingChange }) => {
    const stars = [1, 2, 3, 4, 5];

    return (
        <div className="star-rating my-5">
            {stars.map(star => (
                <img
                    key={star}
                    src={star <= rating ? starFilled : starEmpty}
                    alt={`${star} star`}
                    onClick={() => onRatingChange(star)}
                    style={{ cursor: 'pointer', width: '12%', margin: '0 4%' }}
                />
            ))}
        </div>
    );
};

export default StarRating;