import React from 'react';
import './EndPage.css';
import { useNavigate } from 'react-router-dom';

const EndPage = () => {
    const navigate = useNavigate();

    const handleRestart = () => {
        navigate('/'); // Redirige vers la page d'accueil (assurez-vous que la route '/' pointe vers le début de votre app)
    };

    return (
        <div className="end-page">
            <h1 className="mb-5">Thanks for your answers</h1>
            <button onClick={handleRestart} className="btn-restart mt-5">Restart</button>
        </div>
    );
}

export default EndPage;
